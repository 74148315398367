<template>
  <footer role="contentinfo">
    <div class="content">
      <div class="footer-text">
        <div class="description">
          <img class="logo" src="@/assets/logos/meLembreLogo.svg" alt="Logo Me Lembre" loading="lazy" />
          <p class="text">Comunicação assertiva para facilitar a sua vida. Estamos onde você precisa, quando você precisa.
          </p>
          <p class="qj-logo">
            Uma empresa
            <NuxtLink class="link" to="https://www.quiteja.com.br">
              <img class="img-logo" src="@/assets/logos/quiteJaLogo.svg" alt="Logo Quite Já" loading="lazy" />
            </NuxtLink>
          </p>
        </div>
        <div class="card">
          <TheCard elevation="0">
            <template #default>
              <p class="font-weight-bold">Para sua empresa</p>
              <p class="my-5">Estreite o laço com seus clientes e comunique-se melhor. O MeLembre te ajuda!</p>
              <NuxtLink class="link font-weight-bold" to="/empresa">Saiba mais</NuxtLink>
            </template>
          </TheCard>
        </div>
      </div>
      <div class="footer-links">
        <v-list class="pa-0" role="listbox">
          <v-list-subheader>MeLembre</v-list-subheader>
          <v-list-item role="option">
            <NuxtLink class="link" to="/#about-company">Quem somos</NuxtLink>
          </v-list-item>
          <v-list-item>
            <NuxtLink class="link" to="/#in-the-media">Na Mídia</NuxtLink>
          </v-list-item>
        </v-list>
        <v-list class="pa-0">
          <v-list-subheader>Parceiros</v-list-subheader>
          <v-list-item v-for="(creditor, index) in creditors" :key="creditor.name + index">
            <NuxtLink class="link" :to="`/credor/${creditor.alias}`">{{ creditor.name }}</NuxtLink>
          </v-list-item>
        </v-list>
        <v-list class="pa-0">
          <v-list-subheader>Ajuda</v-list-subheader>
          <v-list-item>
            <NuxtLink class="link" to="/contato">FAQ</NuxtLink>
          </v-list-item>
          <v-list-item>
            <NuxtLink class="link" to="/contato#contact-form">Contato</NuxtLink>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <div class="bg-bottom">
      <div class="bottom">
        <div class="media">
          <div class="icons" v-for="(social, index) in socialLinks" :key="social.name + index">
            <NuxtLink class="link" :to="social.link">
              <TheIcon :icon="social.icon" :size="social.size" type="svg" :name="social.name" />
            </NuxtLink>
          </div>
        </div>
        <div class="copyright">
          <b>{{ year ?? 2024 }}</b> &copy; Todos os direitos reservados <b>MeLembre</b>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { VList, VListSubheader, VListItem } from 'vuetify/components';
import { ref } from 'vue';
import { usePartnersStore } from '@/stores/home/partners'

const props = defineProps(['partnerName'])

const store = usePartnersStore();
const creditors = store.getPartners.filter(partner => partner.name != props.partnerName);
const current = new Date();
const year = `${current.getFullYear()}`;

const socialLinks = ref([
  {
    name: "Facebook Icon",
    link: "https://www.facebook.com/melembre.br",
    icon: "/icons/facebookLogo.svg",
    size: "20"
  },
  {
    name: "Instagram Icon",
    link: "https://www.instagram.com/melembre.br",
    icon: "/icons/instagramLogo.svg",
    size: "20"
  },
  {
    name: "LinkedIn Icon",
    link: "https://www.linkedin.com/company/melembre/",
    icon: "/icons/linkedinLogo.svg",
    size: "20"
  }
])
</script>

<style lang="scss">
@import "@/styles/variables";

footer {
  position: relative;
  bottom: 0;
  width: 100vw;
  background: $white;

  .content {
    padding: 40px 40px 0px;
    max-width: 425px;
    margin: 0 auto;

    .footer-text,
    .footer-text .description,
    .footer-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .footer-text {
      .description {
        .logo {
          width: 142.116px;
          height: 50px;
        }

        .text {
          margin: 34px 0px;
          max-width: 100dvw;
        }

        .qj-logo {
          font-size: 14px;
          width: 235px;
          max-width: 100dvw;

          .img-logo {
            margin-bottom: 10px;
            width: 132.821px;
            height: 39px;
            vertical-align: middle;
            margin-left: 6px;
          }
        }

      }

      .card {
        margin: 34px 0px 35px;

        .default-card {
          background-color: $greyLight;
          padding: 34px 23px 34px 21px;
        }
      }
    }

    .footer-links {
      margin-bottom: 7px;

      .v-list {
        margin-bottom: 27px;

        .v-list-subheader,
        .v-list-item {
          min-height: unset;
        }

        .v-list-subheader {
          color: $greyDark;
          font-size: 16px;
          font-weight: 800;

          .v-list-subheader__text {
            margin: 0 auto;
          }
        }

        .v-list-item {
          padding: 0px;

          a {
            color: $blue;
            font-weight: 600;
            line-height: 28px;
          }
        }
      }
    }
  }

  .bg-bottom {
    background-color: $greyLight;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 33px;

    .media {
      display: flex;
      margin: 34px 0px 20px;
      width: 107.39px;
      justify-content: space-around;

      .icons {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icons a,
      .icons a:link,
      .icons a:visited,
      .icons a:hover,
      .icons a:active {
        color: $blue;
        text-decoration: none;
      }
    }

    .copyright {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 834px) and (max-width: 959px) {
    .content {
      padding: 68px 100px 34px;

      .footer-text {
        justify-content: space-between;

        .card {
          margin: 0px;
        }
      }

      .footer-links {
        margin: 35px 0px 56px;

        .v-list:nth-child(2) {
          margin: 0px 100px 0px 70px;
        }
      }
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1199px) {
    .content {
      padding: 70px 32px;

      .footer-text .card {
        margin: 0px 33px 0px 16px;
      }

      .footer-links {
        .v-list:first-child {
          width: 116px;
        }

        .v-list:nth-child(2) {
          margin: 0px 30px 0px 15px;
        }
      }
    }
  }

  @media screen and (min-width: 834px) {
    .content {
      max-width: 1166px;

      .footer-text,
      .footer-links {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: start;
      }

      .footer-text {
        justify-content: space-between;

        .description {
          align-items: flex-start;
          text-align: start;
          width: 270px;
        }

        .card {
          width: 230px;

          .default-card {
            padding: 33px 30px;
            text-align: start;
            align-items: flex-start;
          }
        }
      }

      .footer-links {

        .v-list-subheader,
        .v-list-subheader__text {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
    }

    .bottom {
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 22px 32px;

      .media {
        margin: 0px;
      }
    }
  }

  @media screen and (min-width: 960px) {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .footer-text {
        width: 100%;
        max-width: 600px;
      }

      .footer-links {
        margin-top: 33px;

        .v-list:nth-child(2) {
          width: 100px;
        }

        .v-list:last-child {
          width: 86px;
        }
      }
    }

    .bottom {
      max-width: 1234px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1200px) {
    .content {
      padding: 66px 0px 69px;

      .footer-text .card {
        margin: 0px;
      }

      .footer-links {
        .v-list:first-child {
          width: 129px;
        }

        .v-list:nth-child(2) {
          margin: 0px 100px 0px 68px;
        }
      }
    }
  }
}
</style>
