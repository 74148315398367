import { default as _400gI44xWaMt6Meta } from "/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/400.vue?macro=true";
import { default as _500c2AmZh9UzgMeta } from "/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/500.vue?macro=true";
import { default as contato6FO3lmyD1WMeta } from "/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/contato.vue?macro=true";
import { default as casas_45bahia6Edgw4iINsMeta } from "/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue?macro=true";
import { default as digiomh4CbFhbDNMeta } from "/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/credor/digio.vue?macro=true";
import { default as pontof4pf5YVtsoMeta } from "/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/credor/ponto.vue?macro=true";
import { default as empresagGXH92jUd4Meta } from "/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/empresa.vue?macro=true";
import { default as indexdaUmjTZE7GMeta } from "/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/index.vue?macro=true";
import { default as infoml1ow1K4RZMeta } from "/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/info.vue?macro=true";
export default [
  {
    name: "400",
    path: "/400",
    component: () => import("/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/400.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "contato",
    path: "/contato",
    component: () => import("/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/contato.vue").then(m => m.default || m)
  },
  {
    name: "credor-casas-bahia",
    path: "/credor/casas-bahia",
    meta: casas_45bahia6Edgw4iINsMeta || {},
    component: () => import("/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue").then(m => m.default || m)
  },
  {
    name: "credor-digio",
    path: "/credor/digio",
    meta: digiomh4CbFhbDNMeta || {},
    component: () => import("/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/credor/digio.vue").then(m => m.default || m)
  },
  {
    name: "credor-ponto",
    path: "/credor/ponto",
    meta: pontof4pf5YVtsoMeta || {},
    component: () => import("/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/credor/ponto.vue").then(m => m.default || m)
  },
  {
    name: "empresa",
    path: "/empresa",
    component: () => import("/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/empresa.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexdaUmjTZE7GMeta || {},
    component: () => import("/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/codebuild/output/src962590364/src/melembre-landing-page-v2/pages/info.vue").then(m => m.default || m)
  }
]