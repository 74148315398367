<template>
  <section class="error-section">
    <h1 class="title">Erro</h1>
    <picture class="image">
      <source srcset="@/assets/images/error/404.webp" media="(min-width: 834px)">
      <source srcset="@/assets/images/error/404-mobile.webp" media="(max-width: 833px)">
      <img v-once alt="Imagem do código 404" loading="lazy" src="@/assets/images/error/404-mobile.webp" />
    </picture>

    <div class="text-center">
      <h1 class="sub-header">Página não encontrada</h1>

      <v-btn class="default-button button" color="primary" type="button" @click="buttonClicked">
        Voltar para Home
      </v-btn>
    </div>
  </section>
</template>

<script setup lang="ts">
import { VBtn } from 'vuetify/components';

const router = useRouter();
const buttonClicked = () => router.replace("/");
</script>

<style lang="scss" scoped>
.error-section {
  background-color: #D0DCFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: none;

  .title,
  .sub-header {
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
  }

  .title {
    text-transform: uppercase;
    margin: 109px 0px 8px;
  }

  .image {
    img {
      max-width: 100%;
    }
  }

  .sub-header {
    margin: 20px 0px;
  }

  .button {
    width: 199px !important;
    height: 52px !important;
    max-width: 100dvw;
    margin-bottom: 55px;
  }

  @media screen and (min-width: 834px) {
    height: 750px;

    .title {
      margin-bottom: 21px;
    }

    .sub-header {
      margin: 12px 0px 32px;
    }
  }

}
</style>