import axios from "axios";

export default defineNuxtPlugin(() => {
  const coreApi = axios.create({
    baseURL: useCurrentEnvironment().apiUrl,
  });

  coreApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        error.response &&
        error.response?.data &&
        error.response?.data?.location
      ) {
        window.location = error.response.data.location;
      } else {
        return Promise.reject(error);
      }
    }
  );
  return {
    provide: {
      coreApi: coreApi
    }
  }
});
