type env = {
  apiUrl: string,
  site_url: string,
  environment: string,
  autosservico_url: string,
  site_key: string,
  movie_desk_token: string,
}

const environment: Array<env> = [
  {
    environment: 'development',
    site_url: ' http://localhost:3000',
    apiUrl: 'http://localhost:5800',
    autosservico_url: 'http://localhost:5912',
    site_key: '6Lc4lH8nAAAAAATnzH-6Tfv8DhDyW217foSuGPhc',
    movie_desk_token: '6aebc0f9-d8e6-4449-a749-c857ca50f067',
  },
  {
    environment: 'staging',
    site_url: 'https://melembre.gorillascode.com',
    apiUrl: 'https://api.melembre.gorillascode.com',
    autosservico_url: 'https://app.melembre.gorillascode.com',
    site_key: '6Lc4lH8nAAAAAATnzH-6Tfv8DhDyW217foSuGPhc',
    movie_desk_token: '6aebc0f9-d8e6-4449-a749-c857ca50f067',
  },
  {
    environment: 'production',
    site_url: 'https://melembre.com.br',
    apiUrl: 'https://api.melembre.com.br',
    autosservico_url: 'https://app.melembre.com.br',
    site_key: '6Lc4lH8nAAAAAATnzH-6Tfv8DhDyW217foSuGPhc',
    movie_desk_token: '6aebc0f9-d8e6-4449-a749-c857ca50f067',
  },

]

export const useCurrentEnvironment = (): env => {
  const config = useRuntimeConfig()
  return environment.find(env => env.environment === config.public.environment) || environment[0]
}