import '@mdi/font/css/materialdesignicons.css'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    defaults: {
      VBtn: {
        style: [{ textTransform: "none" }],
      },
    },
    theme: {
      defaultTheme: "default",
      themes: {
        default: {
          colors: {
            primary: "#3303FF",
          },
        },
      },
    },
  },
  )
  app.vueApp.use(vuetify)
})