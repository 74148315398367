<template>
  <section class="error-section">
    <h1 class="title">Erro</h1>
    <picture class="image">
      <source srcset="@/assets/images/error/500.webp" media="(min-width: 834px)">
      <source srcset="@/assets/images/error/500-mobile.webp" media="(max-width: 833px)">
      <img v-once alt="Imagem do código 500" loading="lazy" src="@/assets/images/error/500-mobile.webp" />
    </picture>

    <div class="text-center">
      <h1 class="sub-header">Erro interno do servidor!</h1>

      <p class="text">
        Aguarde alguns minutos e atualize a página.
      </p>
    </div>
  </section>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.error-section {
  background-color: #D0DCFF;
  height: 374px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: none;

  .title,
  .sub-header {
    font-family: "Open Sans";
    font-weight: bold;
    line-height: 18px;
  }

  .title {
    text-transform: uppercase;
    margin-bottom: 8px;
    font-size: 16px;
  }

  .image {
    img {
      max-width: 100%;
    }
  }

  .sub-header {
    font-size: 18px;
    margin: 20px 0px 7px;
  }

  .text {
    width: 65vw;
    margin: 0 auto;
  }

  .button {
    width: 199px !important;
    height: 52px !important;
    max-width: 100dvw;
  }

  @media screen and (min-width: 834px) {
    height: 750px;

    .title {
      margin-bottom: 21px;
    }

    .sub-header {
      margin: 12px 0px 32px;
    }
  }

}
</style>