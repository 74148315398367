import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  if (process.env.environment != 'production')
    return
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: "https://5096d68592690b5e0dbf6b2c1667212c@o92838.ingest.sentry.io/4506780975628288",
    environment: useCurrentEnvironment().environment,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
    ],
    tracesSampleRate: 1,
    tracePropagationTargets: [
      "localhost",
      "melembre.gorillascode.com",
      /(melembre.gorillascode)/,
      /(api.melembre)/,
      /(melembre.com.br)/,
    ]
  })
})
