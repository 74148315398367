<template>
  <div>
    <Error500Component v-if="props.statusCode == 500" />
    <Error400Component v-else-if="props.statusCode == 400" />
    <Error404Component v-else />
  </div>
</template>

<script setup lang="ts">
const props = defineProps(['statusCode']);
</script>

<style lang="scss" scoped></style>